<template>
  <div id="claimAdd">
    <div>
      <demo-block title="保单信息">
        <van-field label="保单号"  :value="policyInfo.policyNo" readonly />
        <van-field label="被保人" :value="policyInfo.insuredName" readonly />
        <van-field label="保险期限" :value="policyInfo.safeguardTime" readonly/>
      </demo-block>
    </div>

    <van-form @submit="onSubmit">
      <!--报案信息-->
      <demo-block title="报案信息">
        <van-field
            v-model="claimInfo.driveReportVo.reportName"
            name="insuredCardNo"
            label="报案人姓名"
            placeholder="报案人姓名"
            clearable
            :rules="[{ required: true, message: '请填写报案人姓名' }]"
        />
        <van-field
            v-model="claimInfo.driveReportVo.reportPhoneNo"
            name="insuredName"
            label="报案人手机号"
            type="tel"
            placeholder="报案人手机号"
            clearable
            :rules="[{ required: true, message: '请填写报案人手机号' }]"
        />
        <!-- 理赔类型（1-单科不过， 2-重学学车） -->
        <van-field
            readonly
            clickable
            name="claimDriveType"
            :value="claimInfo.driveReportVo.claimDriveTypeName"
            label="理赔类型"
            placeholder="请选择"
            :rules="[{ required: true, message: '请选择理赔类型'}]"
            @click="claimDriveTypeShowPicker = true"
        />
        <van-popup v-model="claimDriveTypeShowPicker" position="bottom">
          <van-picker
              show-toolbar
              :columns="claimDriveTypeColumns"
              @confirm="claimDriveTypeOnConfirm"
              @cancel="claimDriveTypeShowPicker = false"
          />
        </van-popup>

        <!--考试科目（1-科目一， 2-科目二， 3-科目三，4-科目四）-->
        <van-field
            readonly
            clickable
            name="picker"
            v-if="claimInfo.driveReportVo.claimDriveType===1"
            :value="claimInfo.driveReportVo.subjectName"
            label="考试科目"
            placeholder="请选择"
            :rules="[{ required: true, message: '请选择考试科目'}]"
            @click="claimSubjectShowPicker = true"
        />
        <van-popup v-model="claimSubjectShowPicker" position="bottom">
          <van-picker
              show-toolbar
              :columns="claimSubjectColumns"
              @confirm="claimSubjectOnConfirm"
              @cancel="claimSubjectShowPicker = false"
          />
        </van-popup>
        <!--考试省市地址-->
        <van-field
            readonly
            clickable
            name="picker"
            :value="examProvinceAndCityValue"
            label="考试地址"
            placeholder="请选择"
            :rules="[{ required: true, message: '请选择考试地址'}]"
            @click="examProvinceAndCityShowPicker = true"
        />
        <van-popup v-model="examProvinceAndCityShowPicker" position="bottom">
          <van-picker
              show-toolbar
              :columns="provinceAndCityColumns"
              @confirm="examProvinceAndCityOnConfirm"
              @cancel="examProvinceAndCityShowPicker = false"
          />
        </van-popup>
        <!--考试详细地址-->
        <van-field
            v-model="claimInfo.driveReportVo.examAddress"
            name="insuredName"
            label="详细地址"
            placeholder="详细地址"
            clearable
            :rules="[{ required: true, message: '请填写详细地址' }]"
        />
        <!--考试时间-->
        <van-field
            readonly
            clickable
            name="examTime"
            :value="claimInfo.driveReportVo.examTime"
            label="考试时间"
            placeholder="请选择"
            :rules="[{ required: true, message: '请选择考试时间'}]"
            @click="examTimeShowPicker = true"
        />
        <van-popup v-model="examTimeShowPicker" position="bottom">
          <van-datetime-picker
              type="date"
              title="选择考试时间"
              v-model="examTimeCurrentDate"
              @confirm="examTimeConfirm"
              @cancel="examTimeShowPicker = false"
              :min-date="examTimeMinDate"
              :max-date="examTimeMaxDate"
          />
        </van-popup>

        <!--考试费用-->
        <van-field
            v-model="claimInfo.driveReportVo.resit"
            type="number"
            name="resit"
            label="考试费用"
            placeholder="考试费用"
            clearable
            :rules="[{ required: true, message: '请填写考试费用' }]"
        />
        <!--122登录密码-->
        <van-field
            v-model="claimInfo.driveReportVo.govCnPswd"
            name="govCnPswd"
            label="122登录密码"
            placeholder="122登录密码"
            clearable
            :rules="[{ required: true, message: '请填写122登录密码' }]"
        />
      </demo-block>

      <demo-block title="银行卡信息">
        <!--理赔账号类型-->
        <van-field
            readonly
            clickable
            name="claimType"
            :value="claimInfo.claimTypeName"
            label="账号类型"
            placeholder="请选择"
            :rules="[{ required: true, message: '请选择账号类型'}]"
            @click="claimTypeShowPicker = true"
        />
        <van-popup v-model="claimTypeShowPicker" position="bottom">
          <van-picker
              show-toolbar
              :columns="accountTypeColumns"
              @confirm="claimTypeOnConfirm"
              @cancel="claimTypeShowPicker = false"
          />
        </van-popup>
        <!-- 持卡人姓名 -->
        <van-field
            v-model="claimInfo.bankAccountVo.accountName"
            name="accountName"
            label="持卡人姓名"
            placeholder="报案人姓名"
            clearable
            :rules="[{ required: true, message: '请填写报案人姓名' }]"
        />

        <!-- 持卡人证件号 -->
        <van-field
            v-model="claimInfo.bankAccountVo.accountCardNo"
            name="accountCardNo"
            label="持卡人证件号"
            placeholder="持卡人证件号"
            clearable
            :rules="[{ required: true, message: '请填写持卡人证件号' }]"
        />
        <!-- 银行卡号 -->
        <van-field
            v-model="claimInfo.bankAccountVo.accountNo"
            name="accountNo"
            label="银行卡号"
            placeholder="银行卡号"
            :rules="[{ required: true, message: '请填写银行卡号' }]"
        />
        <!-- 开户银行 -->
        <van-field
            readonly
            clickable
            name="bankName"
            :value="claimInfo.bankAccountVo.bankName"
            label="开户银行"
            placeholder="请选择"
            :rules="[{ required: true, message: '请选择开户银行'}]"
            @click="bankCodeShowPicker = true"
        />
        <van-popup v-model="bankCodeShowPicker" position="bottom">
          <van-field v-model="claimInfo.bankAccountVo.bankName"
                     label=""
                     clearable
                     placeholder="没有对应银行时信息请手动输入" >
            <template #button>
              <van-button size="mini" type="default" native-type="button" @click="inputBankNameClick">确定</van-button>
            </template>
          </van-field>

          <van-picker
              show-toolbar
              :columns="bankColumns"
              @confirm="banKConfirm"
              @cancel="bankCodeShowPicker = false"
          />
        </van-popup>

        <van-field
            v-model="claimInfo.bankAccountVo.bankName"
            v-if="'99999' === claimInfo.bankAccountVo.bankCode"
            name="accountCardNo"
            label="银行名称"
            placeholder="银行名称"
            clearable
            :rules="[{ required: true, message: '请填写银行名称' }]"
        />

        <!-- 开户地区 -->
        <van-field
            readonly
            clickable
            :value="bankProvinceAndCityValue"
            label="开户地址"
            placeholder="请选择"
            :rules="[{ required: true, message: '请选择开户地址'}]"
            @click="bankProvinceAndCityShowPicker = true"
        />
        <van-popup v-model="bankProvinceAndCityShowPicker" position="bottom">
          <van-picker
              show-toolbar
              :columns="tianAnProvinceAndCityColumns"
              @confirm="bankProvinceAndCityOnConfirm"
              @change="bankProvinceAndCityOnChange"
              @cancel="bankProvinceAndCityShowPicker = false"
          />
        </van-popup>

        <!-- 开户支行 -->
        <van-field
            readonly
            clickable
            name="subBankName"
            :value="claimInfo.bankAccountVo.subBankName"
            label="开户支行"
            autosize
            placeholder="请选择"
            :rules="[{ required: true, message: '请选择开户支行'}]"
            @click="subBankShowPicker = true"
        />
        <van-popup v-model="subBankShowPicker" position="bottom">
          <van-field v-model="claimInfo.bankAccountVo.subBankName"
                     label=""
                     clearable
                     placeholder="没有对应支行信息请手动输入" >
            <template #button>
              <van-button size="mini" type="default" native-type="button" @click="inputSubBankNameClick">确定</van-button>
            </template>
          </van-field>

          <van-picker
              show-toolbar
              :columns="subBankColumns"
              @confirm="subBankConfirm"
              @cancel="subBankShowPicker = false"
          />
        </van-popup>
      </demo-block>

      <demo-block title="理赔材料">
        <div  v-for="materialType in materialTypeList" :key="materialType.id">
          <van-cell :value="materialType.remark"/>
          <van-field name="uploader" label="">
            <template #input>
              <van-uploader v-model="materialType.fileList" :after-read="uploaderAfterRead" @delete="deleteImg" :max-count="materialType.maxCount"/>
            </template>
          </van-field>
        </div>
      </demo-block>

      <demo-block title="">
        <van-field name="isReadClaimProtocol" label="">
          <template #input>
            <van-checkbox v-model="isReadClaimProtocol" shape="square">我已阅读并同意</van-checkbox>
            <a  @click="claimProtocolShow=true">《协助理赔申请服务协议》</a>
          </template>
        </van-field>
      </demo-block>

      <div style="margin: 16px;">
        <van-row gutter="16">
          <van-col span="12" >
            <van-button plain block type="primary" native-type="button" @click="saveDraft">保存草稿</van-button>
          </van-col>
          <van-col span="12">
            <van-button  block type="info" native-type="submit">
              提交申请
            </van-button>
          </van-col>
        </van-row>
      </div>
    </van-form>


    <van-overlay :show="claimProtocolShow" @click="claimProtocolShow = false">
      <div class="wrapper" @click.stop>
        <div class="claimProtocolBlock">
          <van-row  type="flex" justify="end">
            <van-col justify="end" class="claimProtocolClose">
              <van-icon name="cross" size="30" @click="claimProtocolShow=false"/>
            </van-col>
          </van-row>
          <div class="claimProtocolContent" autosize>
            <div type="flex" justify="center" >
              <h3>《协助理赔授权服务协议》</h3>
              <p>授权由用户（以下简称为“您”）与极速宝（以下简称为“我们”）所提供的驾考保产品申请理赔服务进行授权。</p>
              <p>如果您使用我们提供的产品及服务，则视为您已经详细阅读并同意本授权内容，并遵守本授权规定。您同意授权并使用我们的服务是出于您的个人意愿，并愿自负任何风险，包括但不限于您因执行我们所提供的服务程序导致您或您所使用的计算机系统受到损害等。
              </p>
              <h4>一．您在使用我们的产品及服务过程中，必须遵循以下原则：</h4>
              <p>1.遵守中华人民共和国（不含港澳台）有关的法律和法规；</p>
              <p>2.不得为任何非法目的而使用本产品和服务；</p>
              <p>3.不得利用本产品和服务传输任何骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料；</p>
              <p>4.不得利用本产品和服务进行任何不利于我们的行为；</p>
              <p>5.就我们及合作商业伙伴的服务、产品、业务咨询应采取相应机构提供的沟通渠道，不得在公众场合发布有关我们及相关服务的负面宣传。</p>
              <h4>二．您不得干扰我们正常提供产品和服务，包括但不限于</h4>
              <p>1.攻击、侵入我们的网站服务器或使网站服务器过载；</p>
              <p>2.破解、修改我们提供的客户端程序；</p>
              <p>3.利用程序的漏洞和错误（Bug）破坏服务的正常进行或传播该漏洞或错误（Bug）；</p>
              <p>4.不合理地干扰或阻碍他人使用我们所提供的产品和服务。</p>
              <h4>三．您确认在自助理赔过程中，填写内容及上传材料的真实性与准确性，如因填写或上传错误导致任何理赔问题，后果由您自行承担。</h4>
              <h4>四．我们将采取商业上合理的方式，保护您个人资料的安全。我们将使用通常可以获得的安全技术和程序来保护您的个人资料不被未经授权的访问、使用或泄漏。对于非因我们的疏忽而造成您账号的丢失或资料的泄密，我们不承担任何责任。在不透露您隐私资料的前提下，我们有权对整个用户数据库进行技术分析并对已进行分析、整理后的用户数据库进行商业上的利用。尽管我们对您的隐私权保护做了极大的努力，但是仍然不能保证现有的安全技术措施使您的技术信息等不受任何形式的损失。
              </h4>
            </div>

          </div>
        </div>
      </div>
    </van-overlay>

  </div>

</template>

<script>

import { Notify, Dialog} from 'vant';
import DemoBlock from "@/components/common/DemoBlock";
import {
  getAllNeedClaimInfo,
  getAreaInfoList,
  getDriveClaimAmount,
  getTiananRegionQuery,
  getTiananBankQuery,
  uploadImage,
  addClaimDrive,
  getBankInfoList,
  getSubBankList,
} from "@/api/claim";

export default {
  name: 'claimAdd',
  components: {DemoBlock},
  data() {
    return {
      loading:false,
      claimProtocolShow:false,
      claimDriveTypeShowPicker: false,
      claimSubjectShowPicker: false,
      examProvinceAndCityShowPicker: false,
      examTimeShowPicker: false,
      claimTypeShowPicker: false,
      bankCodeShowPicker: false,
      bankProvinceAndCityShowPicker: false,
      subBankShowPicker:false,
      examTimeMinDate:new Date(2017, 0, 1),
      examTimeMaxDate:new Date(),
      examTimeCurrentDate:new Date(),
      claimDriveTypeColumns: [
        {text: "单科不过", value: 1},
        {text: "重学", value: 2}
        ],
      claimSubjectColumns: [
        {text: "科目一", value: 1},
        {text: "科目二", value: 2},
        {text: "科目三", value: 3},
        {text: "科目四", value: 4}
        ],
      provinceAndCityColumns: [],
      tianAnProvinceAndCityColumns:[],
      bankColumns:[],
      subBankColumns:[],
      tianAnBankInputName:"",
      examProvinceAndCityValue:null,
      bankProvinceAndCityValue:null,
      accountTypeColumns: [
        {text: '本人', value: 1},
        {text: '公司', value: 2},
        {text: '业务人员', value: 3}
      ],
      claimTypeMap: {
        1:"本人",
        2:"公司",
        3:"业务人员"
      },
      isReadClaimProtocol:false,
      policyInfo:{
        policyNo: null,
        insuredCardNo: null,
        insuredName:null,
        safeguardTime:null,
        claimType:null,
        insuredCmpCode:null,
      },
      claimInfo: {
        claimCode: null,
        policyNo: null,
        insuredCardNo: null,
        claimType: null,
        claimTypeName:null,
        claimStatus: null,
        claimStatusName: null,
        remark: null,
        cmpClaimId: null,
        driveReportVo: {
          reportName: null,
          reportPhoneNo: null,
          claimDriveType: null,
          claimDriveTypeName: null,
          subject: null,
          subjectName:null,
          examProvinceCode: null,
          examProvinceName: null,
          examCityCode: null,
          examCityName: null,
          examAddress: null,
          examTime: null,
          resit: null,
          govCnPswd: null
        },
        bankAccountVo: {
          accountName: null,
          accountCardNo: null,
          accountNo: null,
          cardType: null,
          cardTypeName: null,
          bankCode: null,
          bankName: null,
          bankProvinceCode: null,
          bankProvinceName: null,
          bankCityCode: null,
          bankCityName: null,
          subBankName: null,
          subBankCode: null
        },
        materialVoList:[]
      },
      beneficiaryInfoList:[],
      beneficiaryInfoMap:new Map,
      materialTypeList:[],
      materialTypeTempList:[]
    };
  },
  mounted () {
    this.loadData();
  },
  methods: {
    loadData(){
      this.claimInfo.policyNo = this.$route.query.policyNo;
      this.claimInfo.insuredCardNo = this.$route.query.insuredCardNo;
      this.claimInfo.claimCode = this.$route.query.claimCode;
      this.loading = true;

      getAllNeedClaimInfo({
        insuredCardNo: this.claimInfo.insuredCardNo,
        policyNo:this.claimInfo.policyNo,
        claimCode:this.claimInfo.claimCode
      }).then(res => {
          if (0 == res.code) {
            //保单信息
            this.policyInfo = res.result.policyInfo;
            this.claimInfo.claimType = this.policyInfo.claimType;
            this.policyInfo.safeguardTime = this.policyInfo.beginTime + " ~ " + this.policyInfo.endTime;
            //理赔信息
            if (undefined !== res.result.claimInfo) {
              this.claimInfo = res.result.claimInfo;
              this.claimInfo.claimTypeName = this.claimTypeMap[this.claimInfo.claimType];
              if (undefined !== this.claimInfo.driveReportVo) {
                this.examProvinceAndCityValue = this.claimInfo.driveReportVo.examProvinceName + "  " + this.claimInfo.driveReportVo.examCityName;
              }
              if (undefined !== this.claimInfo.bankAccountVo) {
                this.bankProvinceAndCityValue = this.claimInfo.bankAccountVo.bankProvinceName + "  " + this.claimInfo.bankAccountVo.bankCityName;
              }
            }
            //理赔材料信息
            if (undefined !== res.result.materialTypeList) {
              for (var idx in res.result.materialTypeList) {
                var materialType = res.result.materialTypeList[idx];
                if (0 == materialType.typeStatus) {
                  var fileList = [];
                  if (undefined !== this.claimInfo
                      && undefined !== this.claimInfo.materialVoList
                      && this.claimInfo.materialVoList.length > 0) {
                    for (var index in this.claimInfo.materialVoList) {
                      var material = this.claimInfo.materialVoList[index];
                      if (materialType.materialType == material.materialType) {
                        fileList.push({
                          url: material.materialUrl,
                          materialType: material.materialType
                        });

                      }
                    }
                  }
                  materialType.fileList = fileList;
                  this.materialTypeList.push(materialType);
                  this.materialTypeTempList.push(this.deepClone(materialType));
                }
              }
            }

            //受益人信息
            if (undefined !== res.result.beneficiaryInfoList && res.result.beneficiaryInfoList.length > 0) {
              this.beneficiaryInfoList = res.result.beneficiaryInfoList;
              for (var i in this.beneficiaryInfoList) {
                var beneficiaryInfo = this.beneficiaryInfoList[i];
                this.accountTypeColumns.push({
                  text: beneficiaryInfo.bankAccountName,
                  value: beneficiaryInfo.id
                });

                this.beneficiaryInfoMap.set(beneficiaryInfo.id, beneficiaryInfo);
              }
            }

            this.getSystemAreaInfo();
            //处理 天安 银行信息
            if ('1031' == this.policyInfo.insuredCmpCode) {
              this.getTianAnProvince();
              this.getTianAnBank();
            } else {
              this.getSystemBankInfoList();
              // this.getSystemSubBankInfoList();
              // this.tianAnProvinceAndCityColumns = this.provinceAndCityColumns;
            }

          } else {
            Notify({ type: 'warning', message: res.msg });
          }
          this.loading = false;
      })
      .catch(res => {
        console.log(res);
        Notify({ type: 'warning', message: '加载失败' });
      });
    },

    onSubmit(values) {
      if (!values.isReadClaimProtocol) {
        Notify({ type: 'warning', message: '请阅读并同意《协助理赔申请服务协议》' });
        return;
      }
      //材料校验
      var materialVoList_temp = []
      for (var idx in this.materialTypeList) {
        var m = this.materialTypeList[idx];
        if (m.fileList.length < m.minCount) {
          Notify({ type: 'warning', message:  m.materialTitle + '最少' + m.minCount + "张" });
          return;
        }
        for (var index in m.fileList) {
          var file = m.fileList[index];
          materialVoList_temp.push({
            materialType:file.materialType,
            materialUrl:file.url
          });
        }
      }

      this.claimInfo.materialVoList = materialVoList_temp;
      this.claimInfo.claimStatus = 2;
      addClaimDrive(this.claimInfo).then(res => {
        if (0 == res.code) {
          Dialog.alert({
            title: '提示',
            message: '您的理赔申请已提交审核，理赔进度您可通过“我的理赔”功能进行查询',
          }).then(() => {
            // on close
            this.$router.push("/claim/policySelect");
          });
        } else {
          Notify({ type: 'warning', message:  res.msg });
        }
      }).catch(res => {
        console.log(res);
        Notify({ type: 'danger', message:  res.msg });
      });
    },

    saveDraft() {
      if (!this.isReadClaimProtocol) {
        Notify({ type: 'warning', message: '请阅读并同意《协助理赔申请服务协议》' });
        return;
      }
      //理赔材料处理
      var materialVoList_temp = []
      for (var idx in this.materialTypeList) {
        var m = this.materialTypeList[idx];
        for (var index in m.fileList) {
          var file = m.fileList[index];
          materialVoList_temp.push({
            materialType:file.materialType,
            materialUrl:file.url
          });
        }
      }

      this.claimInfo.materialVoList = materialVoList_temp;
      this.claimInfo.claimStatus = 1;
      addClaimDrive(this.claimInfo).then(res => {
        if (0 == res.code) {
          Dialog.alert({
            title: '提示',
            message: '信息已保存为草稿以便下次编辑。如需正式提交请点击“提交审核”按钮',
          }).then(() => {
          });
        } else {
          Notify({ type: 'warning', message:  res.msg });
        }
      }).catch(() => {
        Notify({ type: 'danger', message: "理赔申请失败" });
      });
    },
    claimDriveTypeOnConfirm(value) {
      this.claimInfo.driveReportVo.claimDriveType = value.value;
      this.claimInfo.driveReportVo.claimDriveTypeName = value.text;
      this.claimDriveTypeShowPicker = false;
    },

    claimSubjectOnConfirm(value) {
      this.claimInfo.driveReportVo.subject = value.value;
      this.claimInfo.driveReportVo.subjectName = value.text;
      this.claimSubjectShowPicker = false;

      this.getClaimAmount();
    },
    examProvinceAndCityOnConfirm(values, index) {
      this.examProvinceAndCityValue = values[0] + "  " + values[1];
      var examProvinceIndex = index[0];
      var examProvince = this.provinceAndCityColumns[examProvinceIndex];
      this.claimInfo.driveReportVo.examProvinceCode = examProvince.value;
      this.claimInfo.driveReportVo.examProvinceName = values[0];
      var examCityIndex = index[1];
      var examCity = examProvince.children[examCityIndex];
      this.claimInfo.driveReportVo.examCityCode = examCity.value;
      this.claimInfo.driveReportVo.examCityName = values[1];
      this.examProvinceAndCityShowPicker = false;

      this.getClaimAmount()

    },
    examTimeConfirm(value) {
      var examTime = value.toLocaleDateString();
      examTime = examTime.replaceAll("/", "-");
      this.claimInfo.driveReportVo.examTime = examTime;
      this.examTimeShowPicker = false;
    },

    claimTypeOnConfirm(value) {
      this.claimInfo.bankAccountVo.cardType = value.value;
      this.claimInfo.claimTypeName = value.text;

      if (1 == value.value) {
        this.claimInfo.bankAccountVo.accountName = this.policyInfo.insuredName;
        this.claimInfo.bankAccountVo.accountCardNo = this.policyInfo.insuredCardNo;
        this.claimInfo.bankAccountVo.accountNo = null;
        this.claimInfo.bankAccountVo.bankCode = null;
        this.claimInfo.bankAccountVo.bankName = null;

        this.bankProvinceAndCityValue = null;
        this.claimInfo.bankAccountVo.bankProvinceCode = null;
        this.claimInfo.bankAccountVo.bankProvinceName = null;
        this.claimInfo.bankAccountVo.bankCityCode = null;
        this.claimInfo.bankAccountVo.bankCityName = null;
        this.claimInfo.bankAccountVo.subBankCode = null;
        this.claimInfo.bankAccountVo.subBankName = null;

      } else if (2== value.value || 3 == value.value) {
        this.claimInfo.bankAccountVo.accountName = null;
        this.claimInfo.bankAccountVo.accountCardNo = null;
        this.claimInfo.bankAccountVo.accountNo = null;
        this.claimInfo.bankAccountVo.bankCode = null;
        this.claimInfo.bankAccountVo.bankName = null;

        this.bankProvinceAndCityValue = null;
        this.claimInfo.bankAccountVo.bankProvinceCode = null;
        this.claimInfo.bankAccountVo.bankProvinceName = null;
        this.claimInfo.bankAccountVo.bankCityCode = null;
        this.claimInfo.bankAccountVo.bankCityName = null;
        this.claimInfo.bankAccountVo.subBankCode = null;
        this.claimInfo.bankAccountVo.subBankName = null;
      } else {
        var beneficiaryInfo = this.beneficiaryInfoMap.get(value.value);
        if (undefined !== beneficiaryInfo) {
          this.claimInfo.bankAccountVo.accountName = beneficiaryInfo.bankAccountName;
          this.claimInfo.bankAccountVo.accountCardNo = beneficiaryInfo.cardNo;
          this.claimInfo.bankAccountVo.accountNo = beneficiaryInfo.bankCardNo;
          this.claimInfo.bankAccountVo.bankCode = beneficiaryInfo.bankCode;
          this.claimInfo.bankAccountVo.bankName = beneficiaryInfo.bankName;

          if ( 2 == beneficiaryInfo.cardType){
            this.claimInfo.bankAccountVo.cardType = 2;
          }else {
            this.claimInfo.bankAccountVo.cardType = 3;
          }

          this.bankProvinceAndCityValue = beneficiaryInfo.bankProvinceName + "  "+ beneficiaryInfo.bankCityName;
          this.claimInfo.bankAccountVo.bankProvinceCode = beneficiaryInfo.bankProvinceCode;
          this.claimInfo.bankAccountVo.bankProvinceName = beneficiaryInfo.bankProvinceName;
          this.claimInfo.bankAccountVo.bankCityCode = beneficiaryInfo.bankCityCode;
          this.claimInfo.bankAccountVo.bankCityName = beneficiaryInfo.bankCityName;
          this.claimInfo.bankAccountVo.subBankCode = "";
          this.claimInfo.bankAccountVo.subBankName = beneficiaryInfo.subBankName;
        }

      }

      this.claimTypeShowPicker = false;
    },
    bankProvinceAndCityOnConfirm(values, index) {
      if ('1031' == this.policyInfo.insuredCmpCode) {
        /// 天安
        var examProvince = values[0];
        this.claimInfo.bankAccountVo.bankProvinceCode = examProvince.value;
        this.claimInfo.bankAccountVo.bankProvinceName = examProvince.text;
        var examCity = values[1];
        this.claimInfo.bankAccountVo.bankCityCode = examCity.value;
        this.claimInfo.bankAccountVo.bankCityName = examCity.text;

        this.bankProvinceAndCityValue = this.claimInfo.bankAccountVo.bankProvinceName + "  " + this.claimInfo.bankAccountVo.bankCityName;
        this.bankProvinceAndCityShowPicker = false;
        this.getTianAnSubBank();
      } else {
        this.bankProvinceAndCityValue = values[0] + "  " + values[1];
        var examProvinceIndex = index[0];
        var examProvince1 = this.tianAnProvinceAndCityColumns[examProvinceIndex];
        this.claimInfo.driveReportVo.examProvinceCode = examProvince1.value;
        this.claimInfo.driveReportVo.examProvinceName = examProvince1.text;
        var examCityIndex = index[1];
        var examCity1 = examProvince1.children[examCityIndex];
        this.claimInfo.driveReportVo.examCityCode = examCity1.value;
        this.claimInfo.driveReportVo.examCityName = examCity1.text;
        this.bankProvinceAndCityShowPicker = false;
        this.getSystemSubBankInfoList();
      }
    },
    banKConfirm(value) {
      this.claimInfo.bankAccountVo.bankName = value[0].text;
      this.claimInfo.bankAccountVo.bankCode = value[0].value;
      if ('99999' === this.claimInfo.bankAccountVo.bankCode) {
        // this.claimInfo.bankAccountVo.bankName = "";
      }
      this.bankCodeShowPicker = false;
      if ('1031' == this.policyInfo.insuredCmpCode) {
        this.getTianAnSubBank();
      }else {
        // this.subBankColumns = [];
        this.getSystemSubBankInfoList();
      }
    },
    subBankConfirm(value) {
      this.claimInfo.bankAccountVo.subBankName = value[0].text;
      this.claimInfo.bankAccountVo.subBankCode = value[0].value;
      this.subBankShowPicker = false;
    },
    bankNameInputChange(){
    },
    getClaimAmount(){
      if (undefined !== this.claimInfo.driveReportVo.examProvinceCode
          && undefined !== this.claimInfo.driveReportVo.subject) {
        getDriveClaimAmount({
          provinceCode: this.claimInfo.driveReportVo.examProvinceCode,
          cityCode: this.claimInfo.driveReportVo.examCityCode,
          subject: this.claimInfo.driveReportVo.subject
        }).then(res => {
          if (0 == res.code) {
            this.$set(this.claimInfo.driveReportVo, "resit", res.result.amount);
            // this.claimInfo.driveReportVo.resit = Number(res.result.amount);
          }
        }).catch();
      }

    },
    bankProvinceAndCityOnChange(picker, value, index) {
      if ('1031' == this.policyInfo.insuredCmpCode) {
        if (0===index) {
          this.getTianAnCity(value[0].value);
        }
      }
    },
    getSystemAreaInfo(){
      getAreaInfoList()
          .then(res=> {
            if (0 == res.code) {
              this.provinceAndCityColumns = res.result;
              this.tianAnProvinceAndCityColumns=res.result;
            } else {
              Notify({ type: 'warning', message: res.msg });
            }
          }).catch();
    },
    getTianAnProvince(){
      getTiananRegionQuery({}).then(res=>{
        if (0==res.code) {
          var provinceList = res.result;
          var provinceArr = [];
          for (var idx in provinceList) {
            var province = provinceList[idx];
            provinceArr.push({
              "text":province.name,
              "value":province.code
            });
          }
          this.tianAnProvinceAndCityColumns.push({
            "values":provinceArr
          });

          this.getTianAnCity("11");
        }
      }).catch();
    },
    getTianAnCity(provinceCode){
      getTiananRegionQuery({provinceCode:provinceCode}).then(res=>{

        if (0==res.code) {
          if (2 === this.tianAnProvinceAndCityColumns.length) {
            this.tianAnProvinceAndCityColumns.splice(1,1);
          }
          var cityList = res.result;
          var cityeArr = [];
          for (var idx in cityList) {
            var province = cityList[idx];
            cityeArr.push({
              "text":province.name.split("_")[1],
              "value":province.code,
            });
          }
          this.tianAnProvinceAndCityColumns.push({
            "values":cityeArr
          });
        }
      }).catch();
    },
    getTianAnBank(){
      getTiananBankQuery({reqType:1}).then(res => {

        if (0==res.code) {
          var bankList = res.result;
          var banks = [];
          for (var idx in bankList) {
            var bank = bankList[idx];
            banks.push({
              "text":bank.name,
              "value":bank.directBankCode
            });
          }
          this.bankColumns.push({values:banks});
        }
      }).catch();
    },
    getTianAnSubBank(){
      var bankCode = this.claimInfo.bankAccountVo.bankCode;
      var provinceCode = this.claimInfo.bankAccountVo.bankProvinceCode;
      var cityCode = this.claimInfo.bankAccountVo.bankCityCode;
      getTiananBankQuery({reqType:2, bankcode:bankCode, provinceCode:provinceCode,cityCode:cityCode, bankName:""}).then(res => {
        if (0==res.code) {
          var bankList = res.result;
          var banks = [];
          for (var idx in bankList) {
            var bank = bankList[idx];
            banks.push({
              "text":bank.name,
              "value":bank.code
            });
          }
          if (this.subBankColumns.length >= 1) {
            this.subBankColumns.splice(0,1);
          }
          this.subBankColumns.push({values:banks});
        }
      }).catch();
    },
    getSystemBankInfoList(){
      getBankInfoList().then(res => {
        if (0 == res.code) {
          var bankList = res.result;
          var banks = [];
          for (var idx in bankList) {
            var bank = bankList[idx];
            banks.push({
              "text":bank.bankName,
              "value":bank.bankCode
            });
          }
          this.bankColumns.push({values:banks});
        }
      }).catch();
    },
    getSystemSubBankInfoList(){
      getSubBankList({
        bankCode:this.claimInfo.bankAccountVo.bankCode,
        provinceCode:this.claimInfo.bankAccountVo.bankProvinceCode,
        cityCode:this.claimInfo.bankAccountVo.bankCityCode
      }).then(res => {
        if (0 == res.code) {
          var subBankList = res.result;
          var subBanks = [];
          for (var idx in subBankList) {
            var subBank = subBankList[idx];
            subBanks.push({
              "text":subBank.subBankName,
              "value":subBank.subBankCode
            });
          }
          this.subBankColumns.push({values:subBanks});
        }
      }).catch();
    },
    uploaderAfterRead(file, detail){
      var materialTypeIdx;
      for (var idx in this.materialTypeList) {
        var type = this.materialTypeList[idx];
        var typeTemp = this.materialTypeTempList[idx];
        if (type.fileList.length !== typeTemp.fileList.length) {
          materialTypeIdx = idx;
          typeTemp.fileList = this.deepClone(type.fileList);
        }
      }
      file.status = 'uploading';
      file.message = '上传中...';
      uploadImage({file: file.content}).then(res=> {

        file.status = 'done';
        if (0 == res.code) {
          file.status = 'done';
          // this.claimInfo.materialVoList.push({
          //   "materialType":materialType,
          //   "materialUrl":res.src
          // })
          // this.materialTypeList[materialTypeIdx].fileList.push({
          //   url: res.src,
          //   materialType:this.materialTypeList[materialTypeIdx].materialType
          // });
          this.materialTypeList[materialTypeIdx].fileList[detail.index].url = res.src;
          this.materialTypeList[materialTypeIdx].fileList[detail.index].materialType = this.materialTypeList[materialTypeIdx].materialType;
        }
      }).catch(()=>{
        file.status = 'failed';
        file.message = '上传失败';
      });
    },
    deleteImg() {
      for (var idx in this.materialTypeList) {
        var type = this.materialTypeList[idx];
        var typeTemp = this.materialTypeTempList[idx];
        if (type.fileList.length !== typeTemp.fileList.length) {
          typeTemp.fileList = this.deepClone(type.fileList);
        }
      }
    },

    inputBankNameClick(){
      this.subBankShowPicker=false;
    },
    inputSubBankNameClick(){
      this.subBankShowPicker=false;
    },

    deepClone(source) {
      if (!source && typeof source !== 'object') {
        throw new Error('error arguments', 'deepClone')
      }
      const targetObj = source.constructor === Array ? [] : {}
      Object.keys(source).forEach(keys => {
        if (source[keys] && typeof source[keys] === 'object') {
          targetObj[keys] = this.deepClone(source[keys])
        } else {
          targetObj[keys] = source[keys]
        }
      })
      return targetObj
    }
  },
}
</script>

<style>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
}

.claimProtocolBlock {
  margin: 20px;
  width: 100%;
  height: 90%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
}
.claimProtocolContent {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  /*overflow-y: auto;*/
  /*-webkit-overflow-scrolling: touch;*/
}
.claimProtocolClose{
  position: fixed;
}

</style>
